@use 'variables' as v

form
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    position: relative
    h2.min
        width: 50%
    p
        font-size: 22px
        margin: 0
    .overflow
        height: calc(100vh - 430px)
        margin: 10px 0 30px
        p
            font-size: 16px
            margin-bottom: 1em
    div
        position: relative
        width: calc(50% - 15px)
        label
            display: block
            padding: 8px 0
            &.inline
                display: inline
        .field-icon-button
            background: transparent !important
            color: v.$color-primary
            position: absolute
            right: 0
            &:hover
                color: v.$color-secondary
        input:not([type=radio]):not([type=checkbox]):not([type=file]), select, textarea
            border: 2px solid v.$color-primary
            border-radius: 35px
            font-size: 16px
            height: 70px
            line-height: 1.3em
            padding: 4px 38px
            width: 100%
            &[disabled]
                border-color: v.$color-disabled
        button, .button
            margin: 0
        .search-results
            background-color: v.$color-primary
            border-radius: 4px
            display: none
            max-height: 120px
            overflow: auto
            position: absolute
            top: 107px
            width: 100%
            z-index: 99
            a
                border-bottom: 1px solid v.$color-disabled
                color: white
                cursor: pointer
                display: block
                font-size: 14px
                padding: 6px 8px
                &:hover
                    background-color: v.$color-secondary
                &:last-child
                    border-bottom: none
        input:focus + .search-results, .search-results:hover
            display: block
    .datetime
        input
            width: calc(40% - 10px) !important
        input:nth-child(2)
            margin-right: 20px !important
            width: calc(60% - 10px) !important
    .signatureField
        width: 135px
    .signature
        position: relative
        width: calc(50% - 135px)
        img
            background: white
            height: 85px
            object-fit: contain
            width: 100%
    .full
        width: 100%
        textarea
            height: 120px !important
    .hide.true
        left: 0
        height: 21px
        max-width: 131px
        padding: 0 !important
        position: absolute
        z-index: -1
    .sigCanvas
        margin: 30px auto
        width: 360px
        canvas
            border: 1px solid black
.iniciar-sesion form
    p
        margin: 1em 0 2em
    label
        display: none
    .button.recover
        background: transparent
        color: v.$color-primary
        font-weight: 600
    .button.recover:hover
        color: v.$color-secondary
    .m0
        margin: 0 auto

@media (max-width: 768px)
    form
        p
            font-size: 18px
        h2.min
            width: 100%
        div
            margin-bottom: 10px
            width: 100%
    .iniciar-sesion form
        .button.recover
            height: 30px