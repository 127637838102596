@use 'variables' as v

html, body, #root
    box-sizing: border-box
    font-family: 'Red Hat Display', sans-serif
    height: 100%
    margin: 0
    padding: 0
    width: 100%

#root
    align-items: center
    background: url('../img/paisaje.jpg')
    display: flex
    justify-content: center

a
    color: v.$color-primary
section
    background: white
    border-radius: 40px
    box-sizing: border-box
    font-family: 'Red Hat Display', sans-serif
    max-width: 1024px
    padding: 40px 20px
    position: absolute
    transition: all 800ms
    width: 100%
    &.isLoading .loading
        display: flex !important
    &:not(.iniciar-sesion)
        min-height: calc(100% - 60px)
        header
            min-height: 100px
    *
        box-sizing: border-box
        font-family: 'Red Hat Display', sans-serif
    .overflow
        max-height: calc(100vh - 300px)
        overflow: auto
        padding-right: 20px
    > .overflow
        margin-left: 20px
    .profile
        align-content: center
        align-items: center
        color: v.$color-text
        display: flex
        height: 48px
        justify-content: flex-end
        margin-top: -48px
        text-decoration: none !important
        span
            display: inline-block
            margin-right: 8px
    nav
        border-top: 1px dashed v.$color-text
        margin: 15px 0 45px
        padding-top: 7px
        text-align: center
        width: 100%
        a
            color: v.$color-text
            display: inline-block
            font-size: 16px
            margin: 0 12px
            padding: 8px 0
            text-decoration: none
        .active
            border-bottom: 2px solid v.$color-secondary
            color: v.$color-secondary
    h1, h2, h3, h4, h5, h6, p
        width: 100%
        &.red
            color: orangered

    button, .button
        align-items: center
        align-self: flex-end
        background-color: v.$color-primary
        border: none
        border-radius: 35px
        color: white
        cursor: pointer
        display: inline-flex
        font-size: 16px
        height: 70px
        justify-content: center
        line-height: 1em
        margin: 20px auto 0
        padding: 0 30px
        transition: all 400ms
        min-width: 90px
        text-align: center
        text-decoration: none
        &:hover
            background-color: v.$color-secondary
        &.min
            height: 40px
            margin: 0 0 6px
            padding: 0 12px
        &.gray
            background-color: v.$color-text
        &.red
            background-color: orangered
        &.inline
            margin-right: 4px
            text-transform: capitalize
        &.w-120
            min-width: 120px
        &.w-200
            min-width: 200px
        &[disabled]
            cursor: auto
            opacity: 0.5
            pointer-events: none
    .react-datepicker__navigation
        margin: auto
        min-width: 0

    table
        border: 0
        border-collapse: collapse
        width: 100%
        th, td
            border-bottom: 1px solid v.$color-text
            padding: 8px 15px
            text-align: left
            vertical-align: middle
            a.button.min
                font-weight: 700
                margin: 4px
        th
            border-bottom-color: v.$color-primary
            text-transform: uppercase
        .btn
            padding: 8px 15px 8px 0
            text-align: right

@keyframes spin
    from
        transform: rotate(0deg)
    to
        transform: rotate(359deg)
.loading
    align-items: center
    background-color: white
    display: none
    font-size: 22px
    height: calc(100% - 80px)
    justify-content: center
    position: absolute
    width: calc(100% - 60px)
    z-index: 9999
    span
        animation: spin 1s linear 0s infinite

.go-back
    cursor: pointer
    margin-top: 12px
    position: absolute
.logo
    display: inline-block
    img
        width: 140px

.invisible
    visibility: hidden
.invisible-hidden
    bottom: 0
    height: 1px
    left: 0
    position: absolute
    width: 100px
    z-index: -1
.hidden
    display: none !important
.center
    justify-content: center
    text-align: center
.right
    justify-content: flex-end
    text-align: right
.left
    justify-content: flex-start
    text-align: left

.error:not(:empty)
    background: v.$color-ternary
    color: black
    font-size: 14px
    padding: 4px 20px
    text-align: center
    
.success:not(:empty)
    background: v.$color-secondary
    color: white
    font-size: 14px
    padding: 4px 20px
    text-align: center

.filters
    float: right
    input, select
        border: 1px solid gray
        border-radius: 4px
        margin-left: 4px
        padding: 2px 8px
    .react-datepicker-wrapper
        width: auto
    .react-datepicker__tab-loop
        position: absolute

.popup
    align-items: center
    background-color: rgba(0,0,0,0.7)
    display: flex
    height: 100%
    justify-content: center
    left: 0
    position: fixed
    top: 0
    width: 100%
    z-index: 100
    .content
        background-color: white
        border-radius: 35px
        max-width: 400px
        padding: 20px 40px
        text-align: center
        p, h2
            width: 100% !important
        p
            font-size: 14px
            margin-bottom: 20px
        .button
            margin-right: 8px
            &:last-child
                margin-right: 0


.pagination
    margin-top: 10px
    text-align: center
    a, span
        align-content: center
        align-items: center
        color: v.$color-text
        display: inline-flex
        font-weight: 700
        height: 20px
        justify-content: center
        justify-items: center
        margin: 4px
        text-decoration: none !important
        vertical-align: top
        width: 20px
        .material-icons
            font-size: 1.2em
    span
        color: v.$color-secondary
    .inactive
        opacity: 0.2
        pointer-events: none

.dashboard
    display: flex
    flex-wrap: wrap
    .w-50
        width: 50%
    .w-100
        width: 100%
    select, input[type=text]
        background: #EEEEEE
        border: none
        font-size: 12px
        margin: 8px 0 0 8px
        vertical-align: top
    .overflow
        margin-right: 20px
        max-height: 270px
        padding: 0 0 0 0
    .button
        margin-right: 8px !important
@media (max-width: 1024px)
    section
        padding: 40px 10px
        .overflow
            padding-right: 10px
        nav
            a
                font-size: 15px
                margin: 0 10px
@media (max-width: 768px)
    section
        margin: 10px
        width: calc(100% - 20px)
        &:not(.iniciar-sesion)
            min-height: calc(100% - 20px)
        .overflow
            max-height: calc(100vh - 200px)
        .profile
            font-size: 0
        .menu-button
            align-content: space-between
            align-items: space-between
            display: flex
            flex-wrap: wrap
            height: 19px
            margin-top: -34px
            position: absolute
            width: 30px
            span, &::before, &::after
                background-color: v.$color-text
                border-radius: 2px
                content: ""
                height: 3px
                position: relative
                transform-origin: center center
                transition: all 300ms
                width: 100%
            &.true span
                background-color: transparent
            &.true::before
                transform: rotate(45deg) translate(6px, 5px)
            &.true::after
                transform: rotate(-45deg) translate(6px, -5px)
        nav
            background: white
            left: 0
            max-height: 0
            overflow: hidden
            position: absolute
            transition: all 300ms
            width: calc(100% - 10px)
            z-index: 1
            &.true
                max-height: 340px
                padding-bottom: 60px
            a
                font-size: 18px
                margin: 0
                width: 100%
            .active
                border: none
        table
            thead
                display: none
            tr
                display: block
                padding: 8px
                td
                    border: none
                    display: block
                    padding: 0
                    &.right
                        text-align: left
                    &.false::before
                        content: none !important
                    &.btn
                        display: inline-block
                        &::before
                            display: none
                    a
                        display: inline-block
                        font-size: 18px
                        margin-bottom: 8px
                    button, .button
                        font-size: 14px
    .filters
        float: none
        input, select
            margin-bottom: 8px
            width: 100%
    .agentList
        td::before
            content: "Nombre:"
            display: inline-block
            font-weight: 700
            margin-right: 8px
        td:nth-child(2)::before
            content: "Correo electr�nico:"
        td:nth-child(3)::before
            content: "Tel�fono / Celular:"
    .appointmentList
        tr
            td:not(.btn)::before
                content: ""
                display: inline-block
                font-weight: 700
                margin-right: 8px
            td:nth-child(1)::before
                content: "#"
            td:nth-child(2)::before
                content: "Fecha: "
            td:nth-child(3)::before
                content: "Hora: "
            td:nth-child(4)::before
                content: "Recolector:"
            td:nth-child(5)::before
                content: "Sede:"
        .collector
            td:nth-child(4)::before
                content: "Sede: "
            td:nth-child(5)::before
                content: "Direcci�n: "
            td:nth-child(6)::before
                content: "Horario: "
        .admin
            td:nth-child(4)::before
                content: "Cliente:"
            td:nth-child(5)::before
                content: "Sede:"
    .collectorList
        td::before
            content: "Correo electr�nico: "
            display: inline-block
            font-weight: 700
            margin-right: 8px
        td:nth-child(3)::before
            content: "Tel�fono: "
        td:nth-child(4)::before
            content: "Estado: "
        td:nth-child(5)::before
            content: "Actividad: "
    .clientList
        td:not(.btn)::before
            content: ""
            display: inline-block
            font-weight: 700
            margin-right: 8px
        td:nth-child(1)::before
            content: "Nombre: "
        td:nth-child(2)::before
            content: "NIT: "
        td:nth-child(3)::before
            content: "Correo electr�nico: "
        td:nth-child(4)::before
            content: "Estado: "
    .pointList
        td:not(.btn)::before
            content: ""
            display: inline-block
            font-weight: 700
            margin-right: 8px
        td:nth-child(1)::before
            content: "Nombre: "
        td:nth-child(2)::before
            content: "Municipio / Ciudad: "
        td:nth-child(3)::before
            content: "Direcci�n: "
        td:nth-child(4)::before
            content: "Contacto: "
    .dashboard
        .w-50
            width: 100%

@media (min-width: 769px)
    section
        .overflow-table
            tbody
                border-bottom: 1px solid v.$color-text
                display: block
                height: var(--desktopHeight, calc(100vh - 440px))
                overflow: auto
            thead,
            tbody > tr
                background-color: rgba(0,0,0,0.02)
                display: table
                table-layout: fixed
                width: 100%
                &:nth-child(even)
                    background-color: rgba(0,0,0,0.05)
            thead
                width: calc(100% - 1em)